import React from 'react';

import { Embed } from '@components/Embed';

import { setBackgroundStyle } from '@lib/util';
import { useStaticQuery, graphql } from 'gatsby';

const Mixcloud = props => {
  const data = useStaticQuery(
    graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    `
  );

  const title = `${props.artist} - ${props.title}`;
  const image = data.allImageSharp.edges.find((edge) => {
    return edge.node.fluid.originalName === `${props.cover}.webp`;
  });

  const feed = encodeURIComponent(`/${props.id}/`)
  const src = `https://www.mixcloud.com/widget/iframe/?feed=${feed}`;

  return (
      <Embed
          aspectRatio="1:1"
          frameBorder="0"
          loading="lazy"
          src={src}
          style={setBackgroundStyle(image)}
          title={title}
      />
  );
};

export default Mixcloud;
