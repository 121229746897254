import React, { useEffect, useState } from 'react'

import { Content } from '@components/Content';
import { Anchor } from '@components/Anchor';
import { Seo } from '@components/Seo';
import { Mixcloud } from '@components/Mixcloud';

const Index = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async() => {

      let { mixes } = await import('@data/mixes');

      const mixData = mixes
        .filter(mix => mix.source === 'mixcloud')
        .slice()
        .sort()
        .reverse();

      setData(mixData[0]);
    })();
  }, []);

  return (
    <>
      <Seo title="Home" />
      <Content textblock={true}>
        <section className="item">
          <h1 className="uppercase">Black Quartz #024</h1>

          <p>
            One of Linz's finest record collectors & DJs Mr. Scheutz has recorded an one-hour jazz selection for the Black Quartz series. He was
            in part responsible for the musical arrangement of the Comeonfeet! FastForward&Rewind radio show in the mid-2000s, which ran on a monthly
            basis for many years on Radio FRO.
          </p>

          <div className="item small">
              <Mixcloud {...data} />
          </div>

          <p>
            Sign up to our <Anchor href="http://eepurl.com/dE5CrP" title="Mailchimp Signup">newsletter</Anchor> to stay updated and get downloads of our mixes!
          </p>
        </section>
      </Content>
    </>
  )
};

export default Index
